import { ModuleWithProviders, NgModule, inject } from '@angular/core';

import { CommonModule as ACommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LoginProviderComponent } from '@tytapp/user/login-provider/login-provider.component';
import { OptinsComponent } from '@tytapp/user/optins/optins.component';
import { SocialSignInComponent } from '@tytapp/user/social-sign-in/social-sign-in.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ApiModule } from '../api';
import { TYTCommonModule } from '../common';
import { AuthGuard, LoginGuard, NotLoggedInGuard, SplashGuard } from './auth-guard';
import { ForgotComponent } from './forgot/forgot.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { LoginBounceComponent } from './login-bounce/login-bounce.component';
import { LoginComponent } from './login/login.component';
import { PrivacyIndicatorComponent } from './privacy-indicator.component';
import { PrivacyPolicyService } from './privacy-policy.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserAuthMediatorComponent } from './user-auth-mediator/user-auth-mediator.component';
import { UserService } from './user.service';

const COMPONENTS = [

    /**
     * DIALOGS **********
     * - These must be added to entryComponents below
     */

    LoginComponent,
    ForgotComponent,

    /**
     * WIDGETS **********
     */
    PrivacyIndicatorComponent,
    ImageUploaderComponent,
    SocialSignInComponent,

    /**
     * PAGES ***********
     */

    SignUpComponent,
    OptinsComponent,
    LoginProviderComponent,
    LoginBounceComponent,

    UserAuthMediatorComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ACommonModule,
        RouterModule,
        ImageCropperModule,
        TYTCommonModule,
        ApiModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSlideToggleModule
    ],
    exports: COMPONENTS
})
export class UserModule {
    users = inject(UserService);

    constructor() {
        // CAUTION: Do not register content blocks or conditions here (circular dependency)
        // Instead register content services in core-content-blocks
    }

    static forRoot(): ModuleWithProviders<UserModule> {
        return {
            ngModule: UserModule,
            providers: [
                UserService,
                AuthGuard,
                LoginGuard,
                SplashGuard,
                NotLoggedInGuard,
                PrivacyPolicyService
            ]
        };
    }
}

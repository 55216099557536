import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { getNextMondayAtLocalMidnight, getTodayAtLocalMidnight, getTomorrowAtLocalMidnight } from '@tytapp/common/date-utils';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'tyt-datetime-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: DateTimePickerComponent }
    ]
})
export class DateTimePickerComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy, DoCheck {
    constructor(
        @Optional() public parentFormField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        private elRef: ElementRef,
        private fm: FocusMonitor
    ) {
        if (this.ngControl) { this.ngControl.valueAccessor = this; }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            setTimeout(() => {
                this.focused = !!origin;
                this.stateChanges.next();
            });
        });
    }

    shouldLabelFloat = true;

    date: Date;
    time: number;

    @ViewChild('DateModel') dateModel: NgModel;
    @ViewChild('TimeModel') timeModel: NgModel;

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder = 'Choose a date';

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(dis) {
        this._disabled = coerceBooleanProperty(dis);
        this.stateChanges.next();
    }
    private _disabled = false;

    errorState = false;
    controlType = 'tyt-datetime-picker';

    static nextId = 0;
    @HostBinding() id = `date-time-picker-${DateTimePickerComponent.nextId++}`;
    stateChanges = new Subject<void>();

    focused = false;

    @HostBinding('attr.aria-describedby') describedBy = '';
    setDescribedByIds(ids: string[]) {
      this.describedBy = ids.join(' ');
    }

    get empty() {
        return !this.date;
    }

    @Input()
    get required() {
      return this._required;
    }
    set required(req) {
      this._required = coerceBooleanProperty(req);
      this.stateChanges.next();
    }
    private _required = false;

    get value() {
        return this.stringValue;
    }

    set value(value) {
        this.stringValue = value;
        this.stateChanges.next();
    }

    get hasValue() {
        return !!this.date;
    }

    @Input()
    get stringValue() {
        return this.calculateValue()?.toISOString();
    }

    set stringValue(value) {
        this.writeValue(value ? new Date(value) : new Date());
    }

    get stringDate() {
        if (!this.date || isNaN(this.date.getTime()))
            return null;

        return this.date?.toISOString();
    }

    set stringDate(value: string) {
        this.date = new Date(value);
    }

    @Output()
    stringValueChange = new Subject<string>();

    clear() {
        this.date = undefined;
        this.time = 0;
        this.updateValue();
    }

    onChange: (value: Date) => void;
    onTouched: () => void;

    showPicker(element: EventTarget) {
        if (element instanceof HTMLInputElement)
            element.showPicker();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    fireTouched() {
        this.onTouched?.();
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        if (typeof value === 'string')
            value = new Date(value);

        if (value instanceof Date) {
            if (isNaN(value.getTime())) {
                this.date = this.time = undefined;
            } else {
                this.date = new Date(
                    value.getFullYear(),
                    value.getMonth(),
                    value.getDate()
                );
                this.time = value.getTime() - this.date.getTime();
            }
        } else {
            this.date = this.time = undefined;
        }

        // for MatFormFieldInput
        this.stateChanges.next();
    }

    lastValue: number;

    calculateValue() {
        if (this.date && isNaN(this.date.getTime())) {
            this.onChange?.(null);
            this.stringValueChange.next(null);
            return;
        }

        let value = this.date ?
                new Date(this.date.getTime() + (this.time ?? 0)) :
                null;

        return value;
    }

    updateValue() {
        let value = this.calculateValue();
        if (this.lastValue !== value?.getTime()) {
            this.lastValue = value?.getTime();
            this.onChange?.(value);
            this.stringValueChange.next(value?.toISOString());
        }
    }

    setDateToday() {
        this.date = getTodayAtLocalMidnight();
        this.time = 1000 * 60 * 60 * 6;
        this.updateValue();
    }

    setDateTomorrow() {
        this.date = getTomorrowAtLocalMidnight();
        this.time = 1000 * 60 * 60 * 6;
        this.updateValue();
    }

    setDateNextMonday() {
        this.date = getNextMondayAtLocalMidnight();
        this.time = 1000 * 60 * 60 * 6;
        this.updateValue();
    }

    setDateNow() {
        let now = new Date();

        // Round down to the nearest minute since the picker doesn't expose seconds and milliseconds anyway.
        now.setMilliseconds(0);
        now.setSeconds(0);

        this.writeValue(now);
        this.updateValue();
    }

    onContainerClick(event: MouseEvent) {
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }
}

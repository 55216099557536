<app-form #form (submit)="submit()">
  <div class="standard-page-layout">
    <div class="form-side">
      @if (submitting) {
        <div class="submitting">
          <h1>Setting up your account...</h1>
          <p>Just a second while we get everything ready for you!</p>
          <app-spinner></app-spinner>
        </div>
      }
      <div [class.hidden]="submitting">
        <h1 [innerHTML]="reason | markdownToHtml | trustHtml"></h1>
        <div class="signin-c2a">
          Already Have an Account? <a routerLink="/login">Sign In</a>
        </div>

        <error-message [producer]="form"></error-message>
        <div class="form-fields">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput tytInput required type="text" name="firstName"
              autocomplete="given-name" [(ngModel)]="firstName" />
              @if (form.hasFieldError('firstName')) {
                <mat-error>
                  {{form.getFieldError('firstName')}}
                </mat-error>
              }
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email Address</mat-label>
              <input matInput tytInput required pattern="^[^@]+@[^@]*\.[^@]*$" type="email" name="email"
                [(ngModel)]="email" />
                <mat-error>
                  {{form.getFieldError('email') ?? 'Invalid email address'}}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput tytInput required minlength="2" type="password" name="password"
                  autocomplete="new-password" [(ngModel)]="password" />
                  @if (form.hasFieldError('password')) {
                    <mat-error>
                      {{form.getFieldError('password')}}
                    </mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Username</mat-label>
                  <input matInput tytInput required minlength="2" type="text" name="username"
                    autocomplete="username" [(ngModel)]="username" pattern="[a-z0-9_]+"
                    maxlength="20"
                    invalidMessage="Must contain only lowercase letters, numbers and underscores"/>
                    <mat-error>
                      {{form.getFieldError('username') ?? 'Must contain only letters, numbers and underscores'}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>US Zip Code (Optional)</mat-label>
                    <input matInput tytInput type="number" autocomplete="address-line3" pattern="\d*" name="zip"
                      [(ngModel)]="zip" />
                    </mat-form-field>
                  </div>

                  <div class="signup-c2a">
                    Or Sign Up With:
                  </div>

                  <tyt-social-sign-in
                    label="Sign up"
                    defaultSource="free-signup"
                    analyticsMethod="free-signup"
                    [commPrefs]="optins"
                  ></tyt-social-sign-in>

                  <br/>

                  <div class="terms">
                    <mat-checkbox name="terms" [(ngModel)]="terms" class="signup-wrappable">
                      By signing up via any of the above methods, you agree to TYT’s
                      <a target="_blank" href="/terms">Terms of Use</a>,
                      <a target="_blank" href="/privacy">Privacy Policy</a>, and
                      <a target="_blank" href="/values">Core Values</a>.
                    </mat-checkbox>
                  </div>


                  <tyt-optins [optins]="optins"></tyt-optins>


                  <div style="text-align: center;">
                    @if (enableReCAPTCHA) {
                      <re-captcha [style.display]="'inline-block'" (resolved)="captchaSolved($event)"
                      [siteKey]="recaptchaSiteKey"></re-captcha>
                    }

                    <br />
                  </div>
                  <div class="submit">
                    <button id="bypass-recaptcha" (click)="bypassRecaptcha = true" style="display: none;">bypass recaptcha</button>
                    <button mat-raised-button color="primary" [disabled]="!isValid">Sign Up</button>
                  </div>

                  @if (extendedEmailNote) {
                    <br />
                    <br />
                    <div>
                      <strong>Is your email address entered correctly?</strong><br />
                      In order to ensure you can access your account after signup we have implemented
                      additional checks for common mistakes. Check the message above for more information
                      about why you are seeing this message.<br />
                      <br />
                      Double check the email address you have entered to be sure that you've entered it correctly.
                      If you are sure it's entered correctly, you may have a legitimate email address
                      which these checks don't account for.<br /><br />
                      If you are sure your email address is entered correctly, contact support&#64;tytnetwork.com and we'll
                      help you get your account set up.
                    </div>
                  }

                  <div class="extended-c2a-mobile">
                    <br />
                    <br />
                    <div class="info-note">
                      <strong>Why Sign Up?</strong>
                      <br />

                      Sign up for your free account to unlock these exclusive features:
                      <ul>
                        <li>Personalize TYT.com and the TYT apps to follow topics you're most interested in</li>
                        <li>Track where you left off watching TYT programming across devices</li>
                        <li>Sign petitions with one click</li>
                        <li>More exclusive features coming soon!</li>
                      </ul>
                      Looking for TYT membership? <a routerLink="/billing/membership-benefits">Click here</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="value-prop-side">
                <div class="content-container">
                  <h1 [innerHTML]="valuePropReason | markdownToHtml | trustHtml"></h1>

                  <div class="issues">
                    <div class="issue">
                      <img src="/assets/signup-icons/green_deal.svg" />
                      <div>
                        The Green New Deal
                      </div>
                    </div>
                    <div class="issue">
                      <img src="/assets/signup-icons/medicare.svg" />
                      <div>
                        Medicare For All
                      </div>
                    </div>
                    <div class="issue">
                      <img src="/assets/signup-icons/college_for_all.svg" />
                      <div>
                        College For All
                      </div>
                    </div>
                    <div class="issue">
                      <img src="/assets/signup-icons/living_wage.svg" />
                      <div>
                        A Living Minimum Wage
                      </div>
                    </div>
                    <div class="issue">
                      <img src="/assets/signup-icons/banning_private.svg" />
                      <div>
                        Banning Private Financing For Elections
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />

                  <div class="extended-c2a">
                    <hr />

                    Register your free account to unlock these exclusive features:
                    <ul>
                      <li>Personalize TYT.com and the TYT apps to follow topics you're most interested in</li>
                      <li>Track where you left off watching TYT programming across devices</li>
                      <li>Sign petitions with one click</li>
                      <li>More exclusive features coming soon!</li>
                    </ul>
                    Looking for TYT membership? <a routerLink="/billing/membership-benefits">Click here</a>
                  </div>
                </div>
              </div>

            </div>
          </app-form>

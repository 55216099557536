@if (enableTwitter) {
  <a mat-button [href]="oauthUrlFor('twitter')" class="twitter-social">
    <div class="button-content">
      <div class="logo">
        <img src="/assets/social-icons/twitter_icon.svg" alt="">
      </div>
      <div class="btn-text">{{label}} with Twitter</div>
    </div>
  </a>
}
<a mat-button [href]="oauthUrlFor('facebook')" class="facebook">
  <div class="button-content">
    <div class="logo">
      <img src="/assets/social-icons/facebook_icon.svg" alt="">
    </div>
    <div class="btn-text">{{label}} with Facebook</div>
  </div>
</a>
<a mat-button [href]="oauthUrlFor('google')" class="google">
  <div class="button-content">
    <div class="logo">
      <img src="/assets/social-icons/google_icon.svg" alt="">
    </div>
    <div class="btn-text">{{label}} with Google</div>
  </div>
</a>

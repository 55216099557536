import { Injectable, inject } from '@angular/core';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';
import { environment } from '@tytapp/environment';
import { DevToolsService } from '@tytapp/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService, sleep } from '@tytapp/common';
import { UserService } from '@tytapp/user';
import { ApiUser } from '@tytapp/api';
import { v4 as uuid } from 'uuid';
import { HostApi } from '@tytapp/common';
import { NavigationEnd, Router } from '@angular/router';

declare var dataLayer: any[];
declare var gtag: (...args) => void;

@Injectable()
export class AnalyticsService {
    private devtools = inject(DevToolsService);
    private matSnackBar = inject(MatSnackBar);
    private logger = inject(LoggerService);
    private users = inject(UserService);
    private hostApi = inject(HostApi);
    private router = inject(Router);

    private user: ApiUser;

    init() {
        this.users.sendSocialSignUpBeacons = async method => await this.sendSocialSignupAnalytics(method);
        this.users.sendSignUpBeacons = async () => await this.sendRegisteredBeacons();

        this.registerDevTools();
        this.updateDevStatus();
        this.users.userChanged.subscribe(user => this.user = user);

        if (isClientSide()) {
            this.router.events.subscribe(ev => {
                if (ev instanceof NavigationEnd) {
                    this.sendToFacebook('track', 'PageView');
                    this.sendToAdRoll('pageView');
                }
            });
        }
    }

    sendToAdRoll(method: string, ...args: any[]) {
        let adroll = window['adroll'];
        if (adroll?.track) {
            adroll.track(method, ...args);
        }
    }

    /**
     * Send event beacons to Google Analytics and Facebook Pixel because a
     * new user registration has just occurred (and said user is now logged in)
     */
    async sendRegisteredBeacons() {
        await this.sendBeacons('Registered via Email/Password', () => {
            // Standard GA custom event
            this.sendBeaconGA4('sign_up', {
                method: 'email_and_password',
                app: 'tyt.com',
                path: 'free_signup'
            });
            this.sendBeaconFB('CompleteRegistration', 'tytcom_registered', {
                registration_type: 'tyt_account'
            });
        });
    }

    async sendSocialSignupAnalytics(method: 'free-signup' | 'login') {
        await this.sendBeacons(`Social Signup [${method}]`, () => {
            this.sendBeaconGA4('sign_up', {
                method: 'social',
                app: 'tyt.com',
                path: (method ?? 'unknown').replace(/-/g, '_')
            });
            this.sendBeaconFB('CompleteRegistration', `tytcom_${method}_social_registered`, {
                registration_type: 'tyt_account',
                social_method: method
            });
        });
    }

    async sendBeacons(label: string, callback: Function) {
        if (isServerSide()) {
            this.logger.error(`Fatal Error: App requested beacons be sent for '${label}' on the server side! Ensure that beacons are only sent client side! These beacons will be skipped!`);
            return;
        }

        const beaconMessage = `[Beacon${!this.beaconsEnabled ? '/simulated' : ''}] ${label}`;
        this.logger.info(beaconMessage);

        try {
            const beacon = { label, tags: [] };
            await Zone.current
                .fork({
                    name: 'Analytics beacons',
                    properties: {
                        'tyt:beacon': beacon
                    }
                })
                .run(() => callback())
            ;

            if (this.showBeacons) {
                this.matSnackBar.open(`${beaconMessage} | Events: ${beacon.tags.join(', ')}`, undefined, { duration: 5000 });
            }

        } catch (e) {
            this.logger.error(`Caught error while trying to send beacons for event: ${label}`);
            this.logger.error(e);
            this.logger.error(`These beacons will not be retried.`);

            if (this.showBeacons) {
                this.matSnackBar.open(`[ERROR] ${beaconMessage} | Caught error while sending: ${e.message}`, undefined, { duration: 5000 });
            }
        }

        // Make sure enough time is allowed for the beacons to go out.

        await sleep(3000);
        if (isClientSide() && localStorage['tyt:beacon-logging'] !== 'quiet')
            this.logger.info(`Beacon sending completed.`);
    }

    get currentBeacon() {
        return Zone.current.get('tyt:beacon') as { label: string, tags: string[] };
    }

    sendBeaconFB(eventName: string, contentName: string, metadataInit: Record<string, any> = {}, isCustom = false) {
        if (isServerSide())
            return;

        const eventId = `${eventName}_${uuid()}`;
        const metadata = {
            content_name: contentName,
            user_uuid: this.user ? this.user.uuid : null,
            ...metadataInit,
            eventID: eventId,
        }

        this.currentBeacon.tags.push(`FBA:${eventName}`);

        console.groupCollapsed(`[FBA] ${eventName}/${contentName}, ID: ${eventId} ${!this.beaconsEnabled? '[skipped]' : ''}`);
        console.dir(metadata);
        console.groupEnd();

        if (!this.beaconsEnabled)
            return;

        if (this.hostApi.apiEnabled) {
            this.hostApi.sendMessage({
                type: 'fb_event',
                name: eventName,
                params: metadata
            });
        } else {
            this.sendToFacebook(
                isCustom ? 'trackCustom' : 'track',
                eventName,
                metadata
            );
        }
    }

    sendToFacebook(method: string, ...args: any[]) {
        let fbq = window['fbq'];
        if (fbq === undefined)
            return;

        if (this.beaconsEnabled) {
            fbq(method, ...args);
        }
    }

    sendBeaconGA4(name: string, params: Record<string, any> = {}) {
        if (isServerSide())
            return;

        // Notify GA4 directly of the event, because GTM would require us to set up a trigger and tag for every
        // individual event in order to send custom parameters.

        this.currentBeacon.tags.push(`GA4:${name}`);
        console.groupCollapsed(`[GA4] ${name} ${!this.beaconsEnabled? '[skipped]' : ''}`);
        console.dir(params);
        console.groupEnd();

        if (!this.beaconsEnabled)
            return;

        if (this.hostApi.apiEnabled) {
            this.hostApi.sendMessage({
                type: 'ga_event',
                name: name,
                params
            });
        } else {
            // It is IMPORTANT to call gtag() here and NOT do dataLayer.push([ ... ])
            // gtag.js detects the Arguments object to determine if a datalayer push is a gtag command!
            // This is undocumented and is a horrible design! But that's what you get when you let Google try to
            // provide a simple service!
            gtag('event', name, params);
        }
    }

    async sendSearchAnalytics(event: 'search' | 'view_search_results', search_term: string) {
        if (!isClientSide())
            return;

        await this.sendBeacons(`${ event } event with terms '${ search_term }'`, () => {
            this.sendBeaconGA4(event, { search_term });
        });
    }

    private registerDevTools() {

        this.devtools.rootMenu.items.push({
            id: 'beacons',
            type: 'menu',
            label: 'Beacons',
            icon: 'analytics',
            items: [
                {
                    id: 'beacons_status',
                    type: 'action',
                    label: this.devBeaconsStatus,
                    handler: (action, injector) => {
                        if (this.forceBeacons === true)
                            this.matSnackBar.open('Analytics beacons are currently: Force-Enabled.', undefined, { duration: 5000 });
                        else if (this.forceBeacons === false)
                            this.matSnackBar.open('Analytics beacons are currently: Force-Disabled.', undefined, { duration: 5000 });

                        this.matSnackBar.open(`Analytics beacons are currently: ${this.beaconsEnabled ? 'Enabled' : 'Disabled'}`, undefined, { duration: 5000 });
                    }
                },
                {
                    id: 'enable_beacons',
                    type: 'action',
                    label: 'Force Enable',
                    handler: (action, injector) => {
                        localStorage['tyt:force_beacons'] = '1';
                        this.updateDevStatus();
                        this.matSnackBar.open('Analytics beacons are now force-enabled.', undefined, { duration: 5000 });
                    }
                },
                {
                    id: 'disable_beacons',
                    type: 'action',
                    label: 'Force Disable',
                    handler: (action, injector) => {
                        localStorage['tyt:force_beacons'] = '0';
                        this.updateDevStatus();
                        this.matSnackBar.open('Analytics beacons are now force-disabled.', undefined, { duration: 5000 });
                    }
                },
                {
                    id: 'reset_beacons',
                    type: 'action',
                    label: 'Unforce',
                    handler: (action, injector) => {
                        delete localStorage['tyt:force_beacons'];
                        this.updateDevStatus();
                        this.matSnackBar.open(`Analytics beacons are now set to default (${this.beaconsEnabled ? 'enabled' : 'disabled'})`, undefined, { duration: 5000 });
                    }
                },
                {
                    id: 'show_beacons',
                    type: 'action',
                    label: 'Show Beacons',
                    handler: (action, injector) => {
                        localStorage['tyt:show_beacons'] = '1';
                        this.updateDevStatus();
                        this.matSnackBar.open(`Analytics beacons will now be shown in the snack bar`, undefined, { duration: 5000 });
                    }
                },
                {
                    id: 'hide_beacons',
                    type: 'action',
                    label: 'Hide Beacons',
                    handler: (action, injector) => {
                        delete localStorage['tyt:show_beacons'];
                        this.updateDevStatus();
                        this.matSnackBar.open(`Analytics beacons will no longer show via the snack bar`, undefined, { duration: 5000 });
                    }
                },
                {
                    id: 'send_test_beacon',
                    type: 'action',
                    label: 'Send test beacon',
                    handler: (action, injector) => {
                        this.sendBeacons('This is a test', async () => {
                            this.sendBeaconGA4('engn_test', {
                                aTestParameter: 123
                            });

                            this.sendBeaconFB('engnTest', 'This is a test', {
                                aTestParameter: 123
                            });
                        });
                    }
                },
                {
                    id: 'send_error_beacon',
                    type: 'action',
                    label: 'Send beacon with error',
                    handler: (action, injector) => {
                        this.sendBeacons('This is a test', async () => {
                            this.sendBeaconGA4('engn_test', {
                                aTestParameter: 123
                            });

                            this.sendBeaconFB('engnTest', 'This is a test', {
                                aTestParameter: 123
                            });
                            throw new Error(`This is a fake error`);
                        });
                    }
                }
            ]
        });
    }

    private get devBeaconsStatus() {
        if (this.forceBeacons !== undefined)
            return `Status: Force-${this.forceBeacons ? 'Enabled' : 'Disabled'}`;
        else
            return `${this.beaconsEnabled ? 'Enabled' : 'Disabled'} by default`;
    }

    get forceBeacons() {
        if (!isClientSide())
            return undefined;

        if (localStorage['tyt:force_beacons'] === undefined)
            return undefined;

        return environment.showDevTools && localStorage['tyt:force_beacons'] === '1';
    }

    get showBeacons() {
        if (!isClientSide())
            return false;

        return environment.showDevTools && localStorage['tyt:show_beacons'] === '1';
    }

    get beaconsEnabled() {
        return environment.name === 'production' || this.forceBeacons;
    }

    private updateDevStatus() {
        this.devtools.getActionById('enable_beacons').hidden = this.forceBeacons === true;
        this.devtools.getActionById('disable_beacons').hidden = this.forceBeacons === false;
        this.devtools.getActionById('reset_beacons').hidden = this.forceBeacons === undefined;
        this.devtools.getActionById('beacons_status').label = this.devBeaconsStatus;

        this.devtools.getActionById('show_beacons').hidden = this.showBeacons === true;
        this.devtools.getActionById('hide_beacons').hidden = this.showBeacons === false;
    }

}
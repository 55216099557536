import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUser, UsersApi } from '@tytapp/api';
import { environment } from '@tytapp/environment';
import { isServerSide } from '@tytapp/environment-utils';
import { UserService } from '@tytapp/user';

import { AppConfig } from './app-config';
import { DevToolsService } from './dev-tools.service';
import { LoggerService } from './logger.service';
import { Shell } from './shell';
import { Subject } from 'rxjs';

export interface TermsOverrides {
    lastUpdated?: Date;
    lastAccepted?: Date;
    lastSnoozed?: Date;
}

const OVERRIDES_KEY = 'tyt:overrideTermsAcceptance';
@Injectable()
export class TermsAcceptanceService {
    private appConfig = inject(AppConfig);
    private userService = inject(UserService);
    private shell = inject(Shell);
    private userApi = inject(UsersApi);
    private devTools = inject(DevToolsService);
    private route = inject(ActivatedRoute);
    private logger = inject(LoggerService);

    user: ApiUser = null;

    async init() {
        if (isServerSide())
            return;

        this.installDevTools();
        await this.appConfig.appStatusReady;

        // Track the user identity since userChanged() bounces as the user is
        // updated.

        let identity: string;
        this.userService.userChanged.subscribe(user => {
            if (this.user) {
                this.lastSnoozed = undefined;
            }
            this.user = user;
            let newIdentity = user ? `${user.type}:${user.id}` : undefined;
            if (newIdentity !== identity) {
                identity = newIdentity;
                this.present();
            }
        });
    }

    private applyTermsOverrides(overrides: TermsOverrides) {
        this.overrides = overrides;
        alert(
            `Terms of Service scenario started. The following states will be overridden until you`
            + `next agree to the Terms of Service.\n\n`
            + `Last updated: ${overrides.lastUpdated?.toISOString() ?? '<Never>'}\n`
            + `Last accepted: ${overrides.lastAccepted?.toISOString() ?? '<Never>'}\n`
            + `Last snoozed: ${overrides.lastSnoozed?.toISOString() ?? '<Never>'}\n`
            + `\n`
            + `The app will now restart so that the Terms Acceptance logic will run.`
        );
        window.location.reload();
    }

    private installDevTools() {
        this.devTools.rootMenu.items.push({
            type: 'menu',
            icon: 'assignment',
            label: 'Terms of Service',
            items: [
                {
                    type: 'action',
                    label: 'TOS updated today',
                    icon: 'assignment',
                    handler(item, injector) {
                        injector.get(TermsAcceptanceService).applyTermsOverrides({
                            lastAccepted: new Date('2020-01-01'),
                            lastUpdated: new Date(),
                            lastSnoozed: undefined,
                        });
                    }
                },
                {
                    type: 'action',
                    label: 'TOS snoozed long ago',
                    icon: 'assignment',
                    handler(item, injector) {
                        injector.get(TermsAcceptanceService).applyTermsOverrides({
                            lastAccepted: new Date('2020-01-01'),
                            lastUpdated: new Date(),
                            lastSnoozed: new Date('2020-01-01'),
                        });
                    }
                },
                {
                    type: 'action',
                    label: 'TOS updated 6 months ago',
                    icon: 'assignment',
                    handler(item, injector) {
                        injector.get(TermsAcceptanceService).applyTermsOverrides({
                            lastAccepted: new Date('2020-01-01'),
                            lastUpdated: new Date(new Date().getTime() - 1000*60*60*24*30*6),
                            lastSnoozed: undefined,
                        });
                    }
                },
                {
                    type: 'action',
                    label: 'Clear TOS overrides',
                    icon: 'assignment',
                    handler(item, injector) {
                        injector.get(TermsAcceptanceService).overrides = null;
                        alert(`All Terms state overrides have been removed. The values for the states will come from the backend as it would in production.`);
                    }
                },
                {
                    type: 'action',
                    label: 'Clear current snooze time',
                    icon: 'assignment',
                    handler(item, injector) {
                        let lastSnoozed = injector.get(TermsAcceptanceService).lastSnoozed;
                        injector.get(TermsAcceptanceService).lastSnoozed = null;
                        alert(`The recorded lastSnoozed time of ${lastSnoozed.toISOString() ?? '<Never>'} time has been cleared. The app will now reload.`);
                        window.location.reload();
                    }
                }
            ]
        })
    }

    get snoozeInterval() {
        if (this.appConfig.appStatus.settings['terms_snooze_interval'])
            return 1000 * 60 * 60 * +this.appConfig.appStatus.settings['terms_snooze_interval'];

        return 24 * 60 * 60 * 1000;
    }

    get snoozableWindow() {
        if (this.appConfig.appStatus.settings['forced_terms_acceptance_window'])
            return 1000 * 60 * 60 * +this.appConfig.appStatus.settings['forced_terms_acceptance_window'];

        return 7 * 24 * 60 * 60 * 1000;
    }

    get shouldForce() {
        return !this.snoozable;
    }

    get snoozable() {
        if (!this.lastUpdated)
            return true;

        return this.lastUpdated.getTime() + this.snoozableWindow > Date.now();
    }

    /**
     * Override the state of the Terms of Service acceptance service.
     * This is used in the TYT.com dev tools for testing. Note that this only
     * works if the environment is configured to show dev tools (ie preproduction).
     */
    set overrides(value) {
        this._overrides = undefined;

        if (!value) {
            delete localStorage[OVERRIDES_KEY];
            this.lastSnoozed = undefined;
        } else {
            localStorage[OVERRIDES_KEY] = JSON.stringify({
                lastUpdated: value.lastUpdated?.toISOString(),
                lastAccepted: value.lastAccepted?.toISOString(),
                lastSnoozed: value.lastSnoozed?.toISOString()
            });

            this.lastSnoozed = value.lastSnoozed;
        }
    }

    private _overrides: TermsOverrides;

    /**
     * Get the current devtools overrides. Only available in preproduction builds.
     */
    get overrides(): TermsOverrides {
        if (!environment.showDevTools || !localStorage[OVERRIDES_KEY])
            return undefined;

        if (this._overrides)
            return this._overrides;

        let raw = JSON.parse(localStorage[OVERRIDES_KEY]);
        return {
            lastUpdated: raw.lastUpdated ? new Date(raw.lastUpdated) : undefined,
            lastAccepted: raw.lastAccepted ? new Date(raw.lastAccepted) : undefined,
            lastSnoozed: raw.lastSnoozed ? new Date(raw.lastSnoozed) : undefined,
        }
    }

    get lastUpdated() {
        if (this.overrides)
            return this.overrides.lastUpdated;

        return new Date(this.appConfig.appStatus?.terms_last_updated ?? '2000-01-01');
    }

    get lastAccepted() {
        if (this.overrides)
            return this.overrides.lastAccepted;

        if (!this.user)
            return null;

        return this.user.terms_last_accepted ? new Date(this.user.terms_last_accepted) : null;
    }

    get lastSnoozed() {
        if (localStorage.getItem('tyt:termsLastSnoozed'))
            return new Date(localStorage.getItem('tyt:termsLastSnoozed'));
    }

    set lastSnoozed(value) {
        localStorage.setItem('tyt:termsLastSnoozed', value?.toISOString());
    }

    snooze() {
        this.lastSnoozed = new Date();
    }

    get isSnoozed() {
        if (!this.lastSnoozed || this.lastSnoozed.getTime() > Date.now())
            return false;

        return this.lastSnoozed.getTime() + this.snoozeInterval > Date.now();
    }

    get agreedToLatestTerms() {
        if (!this.user || !this.lastAccepted)
            return false;

        return this.lastAccepted.getTime() > this.lastUpdated.getTime();
    }

    async present() {
        if (this.overrides) {
            this.logger.info(
                `[Terms] The following overrides are in place: `
                + `lastAccepted=${this.overrides.lastAccepted}, `
                + `lastUpdated=${this.overrides.lastUpdated}, `
                + `lastSnoozed=${this.overrides.lastSnoozed}. `
                + `Further snooze action will remove the lastSnoozed override.`
            );
        }

        this.logger.info(
            `[Terms] Current terms acceptance state: `
            + `lastAccepted=${this.lastAccepted}, `
            + `lastUpdated=${this.lastUpdated}, `
            + `lastSnoozed=${this.lastSnoozed}.`
        );

        if (!this.user) {
            this.logger.info(`[Terms] Not signed in.`);
            return;
        }

        if (this.agreedToLatestTerms) {
            this.logger.info(`[Terms] User agreed to terms of service on ${this.lastAccepted}`);
            return;
        }

        if (this.isSnoozed) {
            this.logger.info(`[Terms] User needs to agree to terms of service updated on ${this.lastUpdated}, but snoozed this action on ${this.lastSnoozed}`);
            return;
        }

        this.logger.info(`[Terms] User needs to agree to terms of service updated on ${this.lastUpdated}`);

        if (await this.shell.hasFeature('apps.web.agree_to_terms_dialog')) {
            this._requestUserAccept.next();
        }
    }

    private _requestUserAccept = new Subject<void>();
    readonly requestUserAccept = this._requestUserAccept.asObservable();

    async accept() {
        this.overrides = null;
        await this.userApi.updateUser({ terms_accepted: true })
            .toPromise();
    }
}

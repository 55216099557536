import { Component, inject, Input } from '@angular/core';
import { buildQuery, Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { UserService } from '@tytapp/user/user.service';
import { ApiNewsletterOptins } from '@tytapp/api';
import { buildBooleanSearchParams } from '@tytapp/common/search-params';

@Component({
    selector: 'tyt-social-sign-in',
    templateUrl: './social-sign-in.component.html',
    styleUrls: ['./social-sign-in.component.scss']
})
export class SocialSignInComponent {
    private userService = inject(UserService);
    private shell = inject(Shell);

    @Input() communicationsOptIn: boolean = false;
    @Input() label = 'Sign in';
    @Input() analyticsMethod = 'login';
    @Input() defaultSource = 'login';
    @Input() source: string;
    @Input() commPrefs: ApiNewsletterOptins;

    get enableTwitter() {
        return this.shell.hasFeatureSync('apps.web.enable_twitter_sign_in');
    }

    oauthUrlFor(provider: string) {
        return `/sign-in-with/${provider}?${buildQuery({
            app: 'web',
            source: this.source ?? this.userService.recentSource ?? this.defaultSource,
            source_first_time: this.userService.sourceFirstTime ? 'true' : 'false',
            communications_opt_in: this.communicationsOptIn ?? false,
            return_url: `/login/return?method=${encodeURIComponent(this.analyticsMethod)}${ this.commPrefsParams() }`
        })}`;
    }

    commPrefsParams() {
        if (!this.commPrefs) return '';

        return '&' + buildBooleanSearchParams('comm-pref', this.commPrefs).toString();
    }
}

import { Component, inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUser, ApiUserAuthToken, UsersApi } from '@tytapp/api';
import { DialogComponent, FormComponent, FormHandlingComponent, FormInput, MessageDialogComponent, NamedDialog, sleep } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { isClientSide } from '@tytapp/environment-utils';
import { SubSink } from 'subsink';
import { ForgotComponent } from '../forgot/forgot.component';
import { UserService } from '../user.service';

@NamedDialog('login')
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends DialogComponent implements FormHandlingComponent {
    private userService = inject(UserService);
    private userApi = inject(UsersApi);
    private route = inject(ActivatedRoute);

    private disableRedirect: boolean = false;

    reason: string = "Welcome Back!";
    audienceType: string = 'visitor';
    submitting = false;
    allowSignup = true;

    @FormInput()
    email: string = "";

    @FormInput()
    password: string = "";

    private subsink = new SubSink();
    private returnUrlProvided = false;

    async init(params: { reason?: string, allowSignup?: boolean } = {}) {
        this.subscribe(
            this.route.queryParamMap,
            qp => {
                if (qp.has('return')) {

                    let url = qp.get('return');
                    let accepted = true;

                    // *** SECURITY ***
                    //
                    // If the URL is an external URL, only accept return URLs on either tyt.com or tytnetwork.com.
                    // This is a security precaution to prevent phishing. An example scenario:
                    // - Attacker calls user to sign in at TYT.com to update their billing information
                    // - Victim inspects the URL and logs in successfully on the real TYT.com
                    // - Return URL goes to the phishing page
                    // - Phishing page says "You need to do a CAPTCHA and enter your credentials again to finish signing in"
                    // - Attacker has now stolen credentials

                    if (environment.production) {
                        if (url.startsWith('http')) {
                            if (!url.match(/^https:\/\/([^/\?]*\.)?tyt(network)?\.com.*/))
                                accepted = false;
                        }
                    }

                    if (accepted) {
                        this.logger.info(`Accepted return URL from query parameters: ${qp.get('return')}`);
                        this.userService.bounceURL = url;
                        this.returnUrlProvided = true;
                    }
                }
            }
        );

        if (params.reason !== undefined)
            this.reason = params.reason;

        if (params.allowSignup !== undefined)
            this.allowSignup = params.allowSignup;

        if (!this.router)
            throw "Failed to get a router";

        this.subsink.add(
            this.userService.userChanged.subscribe(user => {
                if (!user || this.disableRedirect)
                    return;

                //this.router.navigate(['home']);
            })
        );

        this.audienceType = this.userService.audienceType;

        if (!this.returnUrlProvided && !this.router.routerState.snapshot.url.startsWith('/login')) {
            this.userService.bounceURL = this.router.routerState.snapshot.url;
        }
    }

    destroy() {
        this.subsink.unsubscribe();
    }

    showForgot() {
        this.shell.showDialog(ForgotComponent);
    }

    @ViewChild('form')
    form: FormComponent;

    async submit() {
        this.submitting = true;
        try {
            await this.signIn(this.email, this.password, true);
        } catch (e) {
            if (isClientSide())
                await sleep(1500);

            if (e.code == 'confirmation_required') {
                this.shell.showDialog(MessageDialogComponent, "Confirm your email address", "You must confirm your email address before logging in. Please check your email to proceed.");
                return;
            } else if (e.code == 'force_reset_password') {
                this.shell.showDialog(MessageDialogComponent, "You must reset your password", "You must change your password in order to log in. Please check your email inbox for instructions.");
                return;
            }

            this.logger.error("Failed to log in");
            this.logger.error(e);
            let message: string;

            if (e.errorType == 'network-error') {
                message = "A network error has occurred. Make sure you are connected to the Internet and try again.";
            } else if (e.error) {
                message = e.error;
            } else if (e.message) {
                message = e.message;
            } else if (typeof ProgressEvent !== 'undefined' && e instanceof ProgressEvent && e.type === 'error') {
                message = 'Uh oh, having trouble reaching TYT.com! Please check your Internet connection.';
            } else {
                message = "" + e;
            }

            this.form.showError(message);

            return;
        } finally {
            this.submitting = false;
        }
    }

    async signIn(username, password, rememberMe) {
        this.disableRedirect = true;
        let userToken: ApiUserAuthToken;

        userToken = await this.userApi.authenticate({
            method: 'tyt',
            email: username,
            password,
            rememberMe
        }).toPromise();

        if (!userToken.token) {
            this.shell.showDialog(MessageDialogComponent, "Confirm your email address", "You must confirm your email address before logging in. Please check your email to proceed.");
            return;
        }

        this.userService.completeLogin(<ApiUser>userToken, userToken.token);

        return Promise.resolve();
    }
}

<mat-menu #matMenu xPosition="before">
  @for (subitem of menu.items; track subitem) {
    @if (!subitem.hidden) {
      @if (subitem.type === 'menu') {
        <tyt-dev-tools-menu
          [parentMenu]="this"
          [menu]="subitem" [isSubMenu]="true"
          />
      }
      @if (subitem.type === 'action') {
        <button
          mat-menu-item
          (click)="subitem.handler(subitem, injector)"
          >
          @if (subitem.icon) {
            <mat-icon>{{subitem.icon}}</mat-icon>
          }
          {{subitem.label}}
        </button>
      }
    }
  }
</mat-menu>

@if (isSubMenu) {
  <button mat-menu-item [matMenuTriggerFor]="matMenu" #trigger="matMenuTrigger" (mouseenter)="hovered()">
    @if (menu.icon) {
      <mat-icon>{{menu.icon}}</mat-icon>
    }
    {{menu.label}}
  </button>
}

@if (!isSubMenu) {
  <button class="root-button" mat-icon-button [matMenuTriggerFor]="matMenu" aria-label="Show Dev Tools Menu">
    <mat-icon>{{menu.icon}}</mat-icon>
  </button>
}
import { Component, inject } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { PageComponent, Transfer } from '@tytapp/common';
import { take } from 'rxjs/operators';
import { ApiUser } from '@tytapp/api';
import { isServerSide } from '@tytapp/environment-utils';
import { parseBooleanSearchParams } from '@tytapp/common/search-params';

@Component({
    selector: 'app-login-bounce',
    templateUrl: './login-bounce.component.html',
    styleUrls: ['./login-bounce.component.scss']
})
export class LoginBounceComponent extends PageComponent {
    private userService = inject(UserService);
    private route = inject(ActivatedRoute);

    @Transfer()
    user: ApiUser;

    get userDisplayName() {
        if (!this.user)
            return null;

        if (!this.user.profile)
            return null;

        if (this.user.profile.display_name)
            return this.user.profile.display_name;

        if (this.user.profile.first_name)
            return this.user.profile.first_name;

        if (this.user.display_name)
            return this.user.display_name;

        return null;
    }

    get userGreeting() {
        let displayName = this.userDisplayName;

        if (displayName)
            return `Hi ${displayName}!`;
        else
            return `Hi!`;
    }

    get isServerSide() {
        return isServerSide();
    }

    async init() {

        this.subscribe(this.userService.userChanged, async (user) => {
            this.user = user;
        });

        // Because we are going to send beacons that can only be sent from
        // the client side, we need to process the login bounce page itself
        // on the client side. Additionally, login bounce waits 2 seconds which
        // causes a very long request (from the SSR perspective) that should not
        // be hit.
        // TODO: The ability to deliver the beacons in SSR would allow us to
        // handle this and have a better user experience

        if (isServerSide())
            return;

        await this.userService.ready;

        let queryParams = await this.route.queryParamMap.pipe(take(1)).toPromise();



        this.subscribe(this.userService.userChanged, async () => {

            let bounceURL = this.userService.bounceURL;
            this.userService.bounceURL = null;

            // Record signup analytics, if necessary

            if (queryParams.get('onboarded') === '1') {
                await this.userService.completeSocialSignup(<any>queryParams.get('method') || 'login', this.userService.user);
            }

            // Never bounce back to the signup page
            if (bounceURL === '/signup')
                bounceURL = null;

            await this.saveCommPrefs(queryParams.getAll('comm-pref'));

            setTimeout(() => {
                this.redirection.go(bounceURL ? bounceURL : '/', true);
            }, 500);
        });
    }

    private async saveCommPrefs(commPrefs: string[]) {
        if (this.user && commPrefs.length) {
            const optins = parseBooleanSearchParams(commPrefs);
            await this.userService.saveCommPrefs(optins);
        }
    }
}

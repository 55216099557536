import { Injectable } from "@angular/core";
import { ApiPressArticle, PressArticlesApi } from "@tytapp/api";
import { Cache, PagedArray } from "@tytapp/common";

@Injectable()
export class PressArticlesService {
    constructor(
        private pressArticlesApi: PressArticlesApi
    ) {
    }

    cache: Cache<ApiPressArticle> = Cache.shared<ApiPressArticle>('press-articles', 1000 * 60 * 15, 100);
    feedCache: Cache<PagedArray<ApiPressArticle>> = Cache.shared<PagedArray<ApiPressArticle>>('press-article-feeds', 1000 * 60 * 15, 20);

    async all(offset?, limit?) {
        return await this.feedCache.fetch(`page-${offset}-${limit}`, async () => await this.pressArticlesApi.all(offset, limit).toPromise());
    }
}
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { ApiPostUsersOmniauth, UsersApi } from '@tytapp/api';
import { buildQuery, Redirection } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { HostApi, Request, Response } from '@tytapp/common';
import { UserService } from '@tytapp/user/user.service';
import { combineLatest } from 'rxjs';

interface SignInRequest extends Request {
    type: 'start_social_login';
    platform: string;
}

interface SignInResponse extends Response {
    type: 'result';
    error?: boolean;
    errorCode?: string;
    errorMessage?: string;
    credentials?: ApiPostUsersOmniauth;
}

@Component({
    template: `<app-spinner></app-spinner>`
})
export class LoginProviderComponent {
    private route = inject(ActivatedRoute);
    private hostApi = inject(HostApi);
    private redirection = inject(Redirection);
    private userService = inject(UserService);
    private usersApi = inject(UsersApi);

    ngOnInit() {
        let state = combineLatest([this.route.params, this.route.queryParams]);

        state.subscribe(async ([ params, query ]) => {
            await this.userService.ready;

            let provider = params.provider;

            if (await this.hostApi.hasCapability(`platform:sign_in_provider:${provider}`)) {
                // This path allows the native app host to provide OS-specific handling for a specific
                // provider. As an example, this enables native Facebook sign in for iOS/Android.

                let response = await this.hostApi.sendRequest<SignInRequest, SignInResponse>({
                    type: 'start_social_login',
                    platform: provider
                });

                if (response.error) {
                    alert(
                        response.errorMessage
                        ?? `Uh oh! An error occurred while signing you in. `
                            + `Please try again. `
                            + `If the issue persists, please contact support@tytnetwork.com.`
                    );
                    return;
                }

                try {
                    await this.usersApi.userOmniauthAuth(response.credentials);
                } catch (e) {
                    if (e.json)
                        e = e.json();

                    Bugsnag.notify(e);

                    alert(
                        `Uh oh! An error occurredf while completing the sign in process. `
                        + `Please try again. `
                        + `If the issue persists, please contact support@tytnetwork.com`
                    );
                }

                if (query['return_url']) {
                    this.redirection.go(query['return_url'], true);
                } else {
                    this.redirection.go('/', true);
                }
            } else {
                let qp = { ...query }

                if (environment.isNativeBuild) {
                    // For native specifically, we won't be signed in as this user within the context of the browser,
                    // so we pass our auth token. Since we're on native, this external URL will get opened in the system's
                    // browser instead of within the native web view. This is important since the user *won't* be signed
                    // into the provider's site within the web view (and should never be).
                    if (this.userService.token)
                        qp.jwt = this.userService.token;

                    // Since we are going out to the web for this, we'll need to rely on the app's deeplinking
                    // functionality for returning. To ensure that the backend does not redirect to the web frontend,
                    // we need to specify our origin
                    qp.return_url = `tyt.open://mobile.tyt.com${qp.return_url ?? '/'}`;
                }

                this.redirection.go(`${environment.urls.platform}/sign-in-with/${provider}?${buildQuery(qp)}`, true);
            }
        });
    }
}
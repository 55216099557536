<div class="inputs"
  role="group"
  [attr.aria-describedby]="describedBy"
  [attr.aria-labelledby]="parentFormField?.getLabelId()">
  <input class="date mat-input-element"
    [disabled]="disabled"
    [class.empty]="!date"
    [matDatepicker]="DatePicker"
    [placeholder]="placeholder"
    [(ngModel)]="date"
    #DateModel="ngModel"
    (ngModelChange)="updateValue()"
    (blur)="fireTouched()"
    (focus)="DatePicker.open()"
    />
    <mat-datepicker
      #DatePicker
    ></mat-datepicker>

    @if (date) {
      <input
        [disabled]="disabled"
        type="time"
        class="time mat-input-element"
        [(ngModel)]="time"
        #TimeModel="ngModel"
        (ngModelChange)="updateValue()"
        (blur)="fireTouched()"
        (focus)="showPicker($event.target)"
        />
    }

    <div class="spacer"></div>

    @if (hasValue) {
      <a href="javascript:;" mat-icon-button (click)="clear()">
        <mat-icon>clear</mat-icon>
      </a>
    }
  </div>
  <div class="hints">
    <div class="spacer hint">
      <ng-content select="[hint]"></ng-content>
    </div>
    <a href="javascript:;" (click)="setDateNow()" [class.disabled]="disabled">
      <mat-icon>today</mat-icon>
      Now
    </a>
    <a href="javascript:;" (click)="setDateToday()" [class.disabled]="disabled">
      <mat-icon>today</mat-icon>
      Today
    </a>
    <a href="javascript:;" (click)="setDateTomorrow()" [class.disabled]="disabled">
      <mat-icon>schedule_send</mat-icon>
      Tomorrow
    </a>
    <a href="javascript:;" (click)="setDateNextMonday()" [class.disabled]="disabled">
      <mat-icon>calendar_view_week</mat-icon>
      Monday
    </a>
  </div>
